import React, {Component} from 'react';
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const PersonCard=React.lazy(()=>import('../src/components/card/Card'))
const WadapatraFront= React.lazy(()=>import('../src/views/wadapatrafront/WadaPatraFront'))
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const SeniorCard = React.lazy(() => import('./components/card/SeniorPersonCard'));
const Card = React.lazy(() => import('./components/card/SeniorCard'));



class App extends Component {

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/card" name="Login Page" render={props => <Card {...props}/>}/>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
            <Route exact path='/wadapatra' name='Wadapatra' render={props => <WadapatraFront{...props}/>}/>
            <Route exact path="/personcard/:id" name="Person Card" render={props => <PersonCard {...props}/>}/>
            <Route exact path="/seniorpersoncard/:id" name="Senior Person Card" render={props => <SeniorCard {...props}/>}/>
            <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>}/>
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
            <Route path="/" name="Home" render={props =>
              (localStorage.getItem("user") != null) ?
                <TheLayout {...props}/> :
                <Redirect from="/dashboard" to="/login"/>
            }/>
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
